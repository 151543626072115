/* src/App.css */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0B0B0A;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  color: #F8F8F8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Footer styles */
.footer {
  margin-top: auto;
  padding: 20px;
  background-color: #0B0B0A;
  color: #F8F8F8;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.footer-content a {
  color: #F8F8F8;
  text-decoration: none;
  margin: 0 15px;
  font-size: 14px;
}

.footer-content a:hover {
  text-decoration: underline;
}

/* Legal page styles */
.legal-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 20px 20px; /* Added top padding */
  text-align: left;
  color: #F8F8F8;
}

.legal-page h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.legal-page h2 {
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 15px;
}

.legal-page h3 {
  font-size: 1.5em;
  margin-top: 25px;
  margin-bottom: 10px;
}

.legal-page h4 {
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.legal-page p, .legal-page ul {
  font-size: 1em;
  line-height: 1.6;
  margin-bottom: 15px;
}

.legal-page ul {
  padding-left: 20px;
}

/* You can add more global styles here if needed */